import { registerApplication, start, LifeCycles } from "single-spa";

/* registerApplication({
  name: "raizen-power-customer-mfe-portal-hub-gd",
  app: () =>
    System.import<LifeCycles>(
      "raizen-power-customer-mfe-portal-hub-gd"
    ),
  activeWhen: ["/"],
});
 */
registerApplication({
  name: "raizen-power-customer-mfe-portal-hub-gd",
  //@ts-ignore
  app: () => System.import("raizen-power-customer-mfe-portal-hub-gd"),
  activeWhen: ["/", (location) => location.pathname === "/"],
});

start({
  urlRerouteOnly: true,
});
